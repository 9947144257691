// Loader.js
import React from 'react';
import './Loader.css'; // Import your loader styles

const Loader = () => {
  return (
    <div className="loader-overlay">
    <div className="loader-content">
      Loading...
      {/* You can add a loading spinner or animation here */}
    </div>
  </div>
  );
}

export default Loader;