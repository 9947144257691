import React, { Component } from 'react';

class EmailForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientId: '5e84d0c6-8ba8-42a4-9654-496ccac8da53',
            clientSecret: '5cce088e-49f9-4b0f-b780-f2c26224e98f',
            uniqueKey: this.getUniqueKey(props.option),
            messageId: props?.replyState?.id,
            senderEmail: '',
            recipientEmails: props?.replyState?.from ? [props?.replyState?.from?.emailAddress?.address] : [''],
            cc: props?.replyState?.ccRecipients ? props?.replyState?.ccRecipients?.map(x=>{return x?.emailAddress?.address}) : [''],
            bcc: props?.replyState?.bccRecipients ? props?.replyState?.bccRecipients?.map(x=>{return x?.emailAddress?.address}) : [''],
            subject: props?.replyState?.subject,
            fromEmail: '',
            fromName: '',
            replyTo: '',
            messageType: 'HTML',
            message: '',
            attachments: '',
            messageTemplateId: '',
            messageTemplateData: '',
            showTemplateDataField: props.option === 'option3' ? true : false,
            presignedUrls: [],
            emailSending: props.isEmailSending
        };
    }

    getUniqueKey(selectedOption) {
        if (selectedOption === "option2" || selectedOption === "option5") {
            return 'c81434ca-c1b7-435c-a834-097e81bb6d42'
        }
        if (selectedOption === "option3") {
            return '799481bf-cc47-4785-bfdf-53c8ba5c5ebc'
        }
        if (selectedOption === "option4") {
            return "a4a938fb-51a0-427f-bf8d-4e48cba48705"
        }
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleRecipientEmailChange = (e, index) => {
        const recipientEmails = [...this.state.recipientEmails];
        recipientEmails[index] = e.target.value;
        this.setState({ recipientEmails });
    };

    handleCCChange = (e, index) => {
        const cc = [...this.state.cc];
        cc[index] = e.target.value;
        this.setState({ cc });
    };

    handleBCCChange = (e, index) => {
        const bcc = [...this.state.bcc];
        bcc[index] = e.target.value;
        this.setState({ bcc });
    };


    handlePresignedUrlChange = (e, index) => {
        const presignedUrls = [...this.state.presignedUrls];
        presignedUrls[index].url = e.target.value;
        this.setState({ presignedUrls });
    };
    handlePresignedFileNameChange = (e, index) => {
        const presignedUrls = [...this.state.presignedUrls];
        presignedUrls[index].fileName = e.target.value;
        this.setState({ presignedUrls });
    };

    addRecipientEmailField = () => {
        const recipientEmails = [...this.state.recipientEmails, ''];
        this.setState({ recipientEmails });
    };

    addCCField = () => {
        const cc = [...this.state.cc, ''];
        this.setState({ cc });
    };

    addBCCField = () => {
        const bcc = [...this.state.bcc, ''];
        this.setState({ bcc });
    };

    addPresignedUrlField = () => {
        const presignedUrls = [...this.state.presignedUrls, { fileName: '', url: '' }];
        this.setState({ presignedUrls });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.sendEmail(this.state)
        console.log('Form values:', this.state);
    };

    render() {
        return (
            <div style={styles.container}>
                <h2>Email Form</h2>
                <form onSubmit={this.handleSubmit}>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>ClientId:</label>
                        <input
                            type="text"
                            name="clientId"
                            value={this.state.clientId}
                            onChange={this.handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Client Secret:</label>
                        <input
                            type="text"
                            name="clientSecret"
                            value={this.state.clientSecret}
                            onChange={this.handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Unique Key (Email registration Key):</label>
                        <input
                            type="text"
                            name="uniqueKey"
                            value={this.state.uniqueKey}
                            onChange={this.handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    {this.props.option === "option5" &&
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Message Id (Copy Message Id from read email option)</label>
                            <input
                                type="text"
                                name="messageId"
                                value={this.state.messageId}
                                onChange={this.handleInputChange}
                                style={styles.input}
                            />
                        </div>}
                    <div style={styles.formGroup}>
                        <label style={styles.label}>From Email:</label>
                        <input
                            type="text"
                            name="fromEmail"
                            value={this.state.fromEmail}
                            onChange={this.handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>From Name:</label>
                        <input
                            type="text"
                            name="fromName"
                            value={this.state.fromName}
                            onChange={this.handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Reply to:</label>
                        <input
                            type="text"
                            name="replyTo"
                            value={this.state.replyTo}
                            onChange={this.handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Recipient's Email:</label>
                        {this.state.recipientEmails.map((recipientEmail, index) => (
                            <input
                                key={index}
                                type="text"
                                name="recipientEmail"
                                value={recipientEmail}
                                onChange={(e) => this.handleRecipientEmailChange(e, index)}
                                style={styles.input}
                            />
                        ))}
                        <button type="button" onClick={this.addRecipientEmailField} style={styles.addButton}>Add Recipient</button>
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>CC:</label>
                        {this.state.cc.map((ccEmail, index) => (
                            <input
                                key={index}
                                type="text"
                                name="cc"
                                value={ccEmail}
                                onChange={(e) => this.handleCCChange(e, index)}
                                style={styles.input}
                            />
                        ))}
                        <button type="button" onClick={this.addCCField} style={styles.addButton}>Add CC</button>
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>BCC:</label>
                        {this.state.bcc.map((bccEmail, index) => (
                            <input
                                key={index}
                                type="text"
                                name="bcc"
                                value={bccEmail}
                                onChange={(e) => this.handleBCCChange(e, index)}
                                style={styles.input}
                            />
                        ))}
                        <button type="button" onClick={this.addBCCField} style={styles.addButton}>Add BCC</button>
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Attachments:</label>
                        <input
                            type="text"
                            name="attachments"
                            value={this.state.attachments}
                            onChange={this.handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Presigned URLs:</label>
                        {this.state.presignedUrls.map((presignedUrl, index) => (
                            <div>
                                <input
                                    type="text"
                                    name="fileName"
                                    value={presignedUrl.fileName}
                                    onChange={(e) => this.handlePresignedFileNameChange(e, index)}
                                    style={styles.presignedName}
                                    placeholder='File Name'
                                />
                                <input
                                    type="text"
                                    name="url"
                                    value={presignedUrl.url}
                                    onChange={(e) => this.handlePresignedUrlChange(e, index)}
                                    style={styles.presignedurl}
                                    placeholder='Presigned URL'
                                />

                            </div>
                        ))}
                        <button type="button" onClick={this.addPresignedUrlField} style={styles.addButton}>Add PresignedUrl</button>
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Subject:</label>
                        <input
                            type="text"
                            name="subject"
                            value={this.state.subject}
                            onChange={this.handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    {this.state.showTemplateDataField && <span> <div style={styles.formGroup}>
                        <label style={styles.label}>Template Id:</label>
                        <input
                            type="text"
                            name="messageTemplateId"
                            value={this.state.messageTemplateId}
                            onChange={this.handleInputChange}
                            style={styles.input}
                        />
                    </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Template Data (If Template Id or Template data is passed then message body will get ignored.): </label>
                            <textarea
                                name="messageTemplateData"
                                value={this.state.messageTemplateData}
                                onChange={this.handleInputChange}
                                style={styles.textarea}
                            />
                        </div></span>}
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Message Type:</label>
                        <input
                            type="text"
                            name="messageType"
                            value={this.state.messageType}
                            onChange={this.handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Message :</label>
                        <textarea
                            name="message"
                            value={this.state.message}
                            onChange={this.handleInputChange}
                            style={styles.textarea}
                        />
                    </div>
                    <button type="submit" style={styles.submitButton}>Send Email</button>
                </form>
            </div>
        );
    }
}

const styles = {
    container: {
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '5px',
        marginBottom: '20px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'left'
    },
    input: {
        width: '100%',
        padding: '8px',
        fontSize: '14px',
        border: '1px solid #ccc',
        borderRadius: '3px',
        marginBottom: '10px'
    },
    presignedName: {
        width: '30%',
        padding: '8px',
        fontSize: '14px',
        border: '1px solid #ccc',
        borderRadius: '3px',
        marginBottom: '10px',
        marginRight: '10px'
    },
    presignedurl: {
        width: '60%',
        padding: '8px',
        fontSize: '14px',
        border: '1px solid #ccc',
        borderRadius: '3px',
        marginBottom: '10px',
    },
    textarea: {
        width: '100%',
        padding: '8px',
        fontSize: '14px',
        border: '1px solid #ccc',
        borderRadius: '3px',
        minHeight: '100px',
    },
    addButton: {
        fontSize: '14px',
        padding: '6px 12px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
        marginTop: '10px'
    },
    submitButton: {
        fontSize: '16px',
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

export default EmailForm;
