import './App.css';
import { useState } from 'react';
import axios from 'axios';
import EmailForm from './send-mail-form';
import Loader from './loader';

function App() {
  const [posts, setPosts] = useState([]);
  const [clientDetails, setClientDetails] = useState({
    clientId: '5e84d0c6-8ba8-42a4-9654-496ccac8da53',
    clientSecret: '5cce088e-49f9-4b0f-b780-f2c26224e98f',
    uniqueKey: '2c45981b-7f91-4011-8a51-75699bcc2b5f'
  });
  const [isEmailSending, setIsEmailSending] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSearchClicked, setIsSearchClicked] = useState(false)
  const [selectedOption, setSelectedOption] = useState("option1");
  const [replyMessageState, setReplyMessageState] = useState([]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientDetails({ ...clientDetails, [name]: value });
  };

  const ReadMail = (e) => {
    e.preventDefault()
    setPosts([])
    setIsLoading(true)
    setIsSearchClicked(true)
    const data = {
      "clientId": clientDetails.clientId,
      "clientSecret": clientDetails.clientSecret,
      "uniqueKey": clientDetails.uniqueKey
    }

    axios //192.168.201.232
      .post(process.env.REACT_APP_APIURL + '/read-mail', data)
      .then(async (data) => {
        console.log(data);
        setIsLoading(false)
        if (data.data.result && data.data.result.data) {
            if (Array.isArray(data.data.result.data.data)) {

            setPosts(data.data.result.data.data);
          } else {
            alert(data.data.result.data.data)
          }
        } else {
          if (data.data.error || data.data.result.error) {
            alert(data.data.result ? data.data.result.error : data.data.message)
          } else {
            alert('Error occured!')
          }
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err.message);
      });
  }

  const SendEmail = (data) => {
    const to = [];
    const cc = [];
    const bcc = [];
    const presignedUrl = [];

    data.recipientEmails.map(x => {
      if (x) {
        to.push({
          address: x
        })
      }
      return x
    })
    data.cc.map(x => {
      if (x) {
        cc.push({
          address: x
        })
      }
      return x;
    })
    data.bcc.map(x => {
      if (x) {
        bcc.push({
          address: x
        })
      }
      return x;
    })
    data.presignedUrls.map(x => {
      if (x) {
        presignedUrl.push({
          url: x.url,
          fileName: x.fileName
        })
      }
      return x;
    })

    const requestData = {
      "clientId": data.clientId,
      "clientSecret": data.clientSecret,
      "uniqueKey": data.uniqueKey,
      "subject": data.subject,
      "messageBodyType": data.messageType.trim(),
      "messageBody": data.message,
      "recipients": to,
      "ccRecipients": cc,
      "bccRecipients": bcc,
      "attachments": data.attachments,
      "fromEmail": data.fromEmail,
      "fromName": data.fromName,
      "replyTo": data.replyTo,
      "presignedUrls": data.presignedUrls,
      "messageTemplateId": data.messageTemplateId,
      "messageTemplateData": data.messageTemplateData,
    };
    setIsEmailSending(true);

    console.log(requestData)
    axios
      .post(process.env.REACT_APP_APIURL + '/send-mail', requestData) //192.168.201.232
      .then((data) => {
        if (data.data && data.data.result && data.data.result.mailSent) {
          alert('Email Sent');
          window.location.reload();
        } else {
          if (data.data && (data.data.error || (data.data.result && data.data.result.error))) {
            alert(data.data.result ? (typeof data.data.result.error === 'object' ? (data.data.result.error.message ? data.data.result.error.message : "Something went wrong! Please contact admin.") : data.data.result.error) : (data.data.message ? data.data.message : "Something went wrong! Please contact admin."))
          } else {
            alert('Error occured while sending email: ')
          }
        }
        console.log(data)
        setIsEmailSending(false);
      })
      .catch((err) => {
        alert(err.message);
        setIsEmailSending(false);

      });
  }

  const ReplyEmail = (data) => {
    const to = [];
    const cc = [];
    const bcc = [];
    const presignedUrl = [];

    data.recipientEmails.map(x => {
      if (x) {
        to.push({
          address: x
        })
      }
      return x
    })
    data.cc.map(x => {
      if (x) {
        cc.push({
          address: x
        })
      }
      return x;
    })
    data.bcc.map(x => {
      if (x) {
        bcc.push({
          address: x
        })
      }
      return x;
    })
    data.presignedUrls.map(x => {
      if (x) {
        presignedUrl.push({
          url: x.url,
          fileName: x.fileName
        })
      }
      return x;
    })

    const requestData = {
      "clientId": data.clientId,
      "clientSecret": data.clientSecret,
      "uniqueKey": data.uniqueKey,
      "subject": data.subject,
      "messageBodyType": data.messageType.trim(),
      "messageBody": data.message,
      "recipients": to,
      "ccRecipients": cc,
      "bccRecipients": bcc,
      "attachments": data.attachments,
      "fromEmail": data.fromEmail,
      "fromName": data.fromName,
      "replyTo": data.replyTo,
      "presignedUrls": data.presignedUrls,
      "messageTemplateId": data.messageTemplateId,
      "messageTemplateData": data.messageTemplateData,
      "messageId" : data.messageId
    };
    setIsEmailSending(true);

    console.log(requestData)
    axios
      .post(process.env.REACT_APP_APIURL + '/reply-mail', requestData) //192.168.201.232
      .then((data) => {
        if (data.data && data.data.result && data.data.result.mailSent) {
          alert('Email Sent');
          window.location.reload();
        } else {
          if (data.data && (data.data.error || (data.data.result && data.data.result.error))) {
            alert(data.data.result ? (typeof data.data.result.error === 'object' ? (data.data.result.error.message ? data.data.result.error.message : "Something went wrong! Please contact admin.") : data.data.result.error) : (data.data.message ? data.data.message : "Something went wrong! Please contact admin."))
          } else {
            alert('Error occured while sending email: ')
          }
        }
        console.log(data)
        setIsEmailSending(false);
      })
      .catch((err) => {
        alert(err.message);
        setIsEmailSending(false);

      });
  }
  const GetRecientDetails = (receipts) => {
    let text = ' ';
    receipts.map(x =>
      text = `${text} ${x.emailAddress.name} (${x.emailAddress.address}) `
    )
    if (!text.trim()) {
      text = "-";
    }
    return text;
  }

  const replyScreen = (emaildata)=>{
    setSelectedOption("option5")
    setReplyMessageState(emaildata)
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }
  return (
    <div className="App">
      <h1>Select an Option:</h1>
      <label className='pointer'>
        <input type="radio" onChange={handleOptionChange} checked={selectedOption === 'option1'} name="option" value="option1" /> Read Email By Graph API
      </label>
      <label className='pointer'>
        <input type="radio" onChange={handleOptionChange} checked={selectedOption === 'option2'} name="option" value="option2" /> Send Email By Graph API
      </label>
      <label className='pointer'>
        <input type="radio" onChange={handleOptionChange} checked={selectedOption === 'option3'} name="option" value="option3" /> Send Email By Send Grid
      </label>
      <label className='pointer'>
        <input type="radio" onChange={handleOptionChange} checked={selectedOption === 'option4'} name="option" value="option4" /> Send Email By SMTP
      </label>
      <label className='pointer'>
        <input type="radio" onChange={handleOptionChange} checked={selectedOption === 'option5'} name="option" value="option5" /> Reply Email By Graph API
      </label>

      <br></br>
      <br></br>
      {isEmailSending && <Loader></Loader>}
      {selectedOption === "option1" && <div style={styles.container}>
        <h2>Read Mail Form</h2>
        <form onSubmit={ReadMail}>
          <div style={styles.formGroup}>
            <label style={styles.label}>ClientId:</label>
            <input
              type="text"
              name="clientId"
              value={clientDetails.clientId}
              onChange={handleInputChange}
              style={styles.input}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Client Secret:</label>
            <input
              type="text"
              name="clientSecret"
              value={clientDetails.clientSecret}
              onChange={handleInputChange}
              style={styles.input}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Unique Key (Email registration Key):</label>
            <input
              type="text"
              name="uniqueKey"
              value={clientDetails.uniqueKey}
              onChange={handleInputChange}
              style={styles.input}
            />
          </div>
          <button type="submit" style={styles.submitButton}>Read Email</button>
        </form>
      </div>}
      {selectedOption === "option1" && posts.length > 0 && <div>

        <br></br>
        <br></br>
        <div className="grid-container">
          <div >
            {posts.map((x) =>
              <>
                <div>MessageId : {x.id}</div>
                <div className="grid-item grid-container-mail" key={x.id}>
                  <div className='grid-item email-details'>
                    <table  border="1">
                      <tr>
                        <td align='center'><h4>Subject</h4></td>
                        <td align='center'>{x.subject}</td>
                      </tr>
                      <tr>
                        <td align='center'><h4>From:</h4></td>
                        <td align='center'> {x.from.emailAddress.name}({x.from.emailAddress.address})</td>
                      </tr>
                      <tr>
                        <td align='center'><h4>To:</h4></td>
                        <td align='center'> {GetRecientDetails(x.toRecipients)}</td>
                      </tr>
                      <tr>
                        <td align='center'><h4>cc:</h4></td>
                        <td align='center'> {GetRecientDetails(x.ccRecipients)}</td>
                      </tr>
                      <tr>
                        <td align='center'><h4>bcc:</h4></td>
                        <td align='center'> {GetRecientDetails(x.bccRecipients)}</td>
                      </tr>
                      <tr>
                        <td align='center'><h4>SentTime:</h4></td>
                        <td align='center'>  {x.sentDateTime}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                <button align="center"  style={styles.submitButton} onClick={()=>{replyScreen(x)}}>Reply Email</button>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className='grid-item email-details'>
                    <h4>Body </h4>
                    <p >{x?.body?.content}</p>
                      <div>
                        <hr></hr>
                        <h4>Unique Body</h4>
                        <p>{x?.uniqueBody?.content}</p>
                      </div>
                  </div>
                  {/* <div className='grid-item email-details'>
                    <h4>Body (Text format)</h4>
                    <p >{x.bodyPreview}</p>
                    {x.body.contentType === "html" &&
                      <div>
                        <hr></hr>
                        <h4>Body (HTML format)</h4>
                        <p dangerouslySetInnerHTML={{ __html: x.body.content }}></p>
                      </div>
                    }
                  </div> */}
                  <div className='grid-item email-details'>
                    <h2>Attachments</h2>
                    <p >{x.newAttachments && x.newAttachments.map(x1 => {
                      const url = process.env.REACT_APP_APIURL + '/' + x1
                      return (
                        <div>
                          {x1 && <a href={url}> {x1}</a>}
                        </div>
                      )
                    })}</p>
                  </div>
                </div>

              </>
            )}

          </div>

        </div>
      </div>}
      {selectedOption === "option1" && posts.length === 0 && !isLoading && isSearchClicked && <div>

        <h2>No new email found.</h2></div>}
      {selectedOption === "option1" && posts.length === 0 && isLoading && <div>
        <h2>Loading...</h2></div>}


      {selectedOption === "option2" && <EmailForm isEmailSending={isEmailSending} option={selectedOption} sendEmail={SendEmail}></EmailForm>}
      {selectedOption === "option3" && <EmailForm isEmailSending={isEmailSending} option={selectedOption} sendEmail={SendEmail}></EmailForm>}
      {selectedOption === "option4" && <EmailForm isEmailSending={isEmailSending} option={selectedOption} sendEmail={SendEmail}></EmailForm>}
      {selectedOption === "option5" && <EmailForm isEmailSending={isEmailSending} option={selectedOption} replyState = {replyMessageState} sendEmail={ReplyEmail}></EmailForm>}
    </div >

  );

}

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: '5px',
    marginBottom: '20px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'left'
  },
  input: {
    width: '100%',
    padding: '8px',
    fontSize: '14px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    marginBottom: '10px'
  },
  textarea: {
    width: '100%',
    padding: '8px',
    fontSize: '14px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    minHeight: '100px',
  },
  addButton: {
    fontSize: '14px',
    padding: '6px 12px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    marginTop: '10px'
  },
  submitButton: {
    fontSize: '16px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};
export default App;
